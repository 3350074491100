<template>
  <shipblu-prompt
    class="shipment-modal"
    @close="closeShipmentsModal"
    :active.sync="uploadShipmentsModal"
    :title="uploadFileType === 'uploadFulfillment' ? $t('Upload Products') : $t('Upload Orders')"
    :buttons-hidden="true">
    <div class="text-center mt-5" v-if="uploadFileType === 'uploadShipments' || uploadFileType === 'uploadFulfillment'">
      <vs-button><a :href="file_URl" class="text-white">{{$t('Download Template')}}</a></vs-button>
    </div>
    <div class="text-center mt-5"  v-if="uploadFileType === 'uploadAutoZoning'">
        <a :href="newTemplateFileURL" class="text-blue-100 underline inline-block mt-1">{{$t('Download auto-zoning template')}}</a>
    </div>
    <div class="excel-import">
      <input type="file" ref="fileInput" class="hidden" accept=".xlsx, .xls" @change="handleClick">
      <div
        @click="$refs.fileInput.click(), mixPanelActivate('UPLOAD_V2_UPLOAD_FILE')"
        @drop="handleDrop"
        @dragover="handleDragover"
        @dragenter="handleDragover"
        class="w-1/2 m-auto mt-10 mb-10 px-8 py-16 cursor-pointer text-center border-2 border-dashed d-theme-border-grey-light d-theme-dark-bg text-xl">
        <feather-icon icon="UploadCloudIcon" svgClasses="h-16 w-16 stroke-current text-grey" class="block" />
        <span>{{$t('Drop Excel File or')}} </span>
        <span class="font-medium text-primary" @click.stop="$refs.fileInput.click()">{{$t('Browse')}}</span>
      </div>
    </div>
    <div v-if="file" class="w-4/5 m-auto text-center mb-3">
      <vs-progress :percent="progress" color="primary"></vs-progress>
      <p>{{progress}}%</p>
    </div>
  </shipblu-prompt>
</template>

<script>
import axios from '../../../axios.js'
import common from '../../../assets/utils/common'
import i18nData from '../../../i18n/i18nData'
import { sendRequest } from '../../../http/axios/requestHelper'
import ShipbluPrompt from '../../../layouts/components/ShipBluPrompt.vue'
import {sendFulfillmentRequest} from '../../../http/axios/requestHelper.js'

export default {
  props: ['uploadShipmentsModal', 'merchant', 'userMerchant', 'uploadFileType'],
  data () {
    return {
      file: false,
      progress: 0,
      myProgress: 0,
      closeInterval: 0,
      newTemplateFileURL: 'https://staging-publicstorage1123538a-1p8k71zpb6h3c.s3.eu-west-3.amazonaws.com/ShipBlu+Template+v2.1-beta.xlsx',
      file_URl: '',
      releaseVersion: process.env.VUE_APP_RELEASE_VERSION,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }
    }
  },
  watch: {
    'uploadShipmentsModal' (val) {
      if (val === true) {
        clearInterval(this.closeInterval)
        this.getTemplate()
      }
    },
    'progress' (val) {
      if (val === 95) {
        clearInterval(this.myProgress)
      }
    }
  },
  methods: {
    mixPanelActivate (type) {
      if (this.releaseVersion !== 'staging') {
        this.$mixpanel.track(type, {
          merchant_name: this.merchant.name,
          merchant_email: this.userMerchant.email,
          source: /(android)/i.test(navigator.userAgent)
        })
      }
    },
    getTemplate () {
      const url = this.uploadFileType === 'uploadFulfillment' ? 'api/v1/fc/products/bulk-upload/' : 'api/v1/orders-upload/'
      if (this.uploadFileType === 'uploadFulfillment') {
        sendFulfillmentRequest(false, false, this, url, 'get', null, true, 
          (response) => {
            this.file_URl = response.data.file_url
          }
        )
      } else {
        sendRequest(false, false, this, url, 'get', null, true, 
          (response) => {
            this.file_URl = response.data.file_url
          }
        )
      }

    },
    closeShipmentsModal () {
      this.$emit('uploadShipmentsModal', false)
      this.progress = 0
      this.file = false
    },
    handleClick (e) {
      const files = e.target.files
      const rawFile = files[0]
      if (!rawFile) return
      this.uploadFile(rawFile)
    },
    handleDragover (e) {
      e.stopPropagation()
      e.preventDefault()
      e.dataTransfer.dropEffect = 'copy'
    },
    handleDrop (e) {
      e.stopPropagation()
      e.preventDefault()
      const files = e.dataTransfer.files
      if (files.length !== 1) {
        this.$vs.notify({
          title: 'Login Attempt',
          text: 'Only support uploading one file!',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
        return
      }
      const rawFile = files[0] // only use files[0]
      if (!this.isExcel(rawFile)) {
        this.$vs.notify({
          title: 'Login Attempt',
          text: 'Only supports upload .xlsx, .xls, .csv suffix files',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
        return false
      }
      this.uploadFile(rawFile)
    },
    isExcel (file) {
      return /\.(xlsx|xls|csv)$/.test(file ? file.name : '')
    },
    uploadFile (file) {
      if (this.uploadFileType === 'uploadShipments' || this.uploadFileType === 'uploadFulfillment') {
        if (!this.isExcel(file)) {
          this.$vs.notify({
            color: 'warning',
            title: i18nData[this.$i18n.locale]['Warning'],
            text: 'Only supports upload .xlsx, .xls, .csv suffix files',
            position: 'top-center'
          })
          return false
        }
        this.$refs['fileInput'].value = null // fix can't select the same excel
        this.progress = 0
        this.file = true
        const formData = new FormData()
        formData.append(this.uploadFileType === 'uploadFulfillment'  ? 'products' : 'orders', file)
        if (!this.isOnline) this.$emit('uploadShipmentsModal', false)
        this.myProgress = setInterval(() => {
          this.progress += 1
        }, 500)
        this.headers = {
          ...this.headers,
          'Accept-Language': this.$i18n.locale
        }
        const url = this.uploadFileType === 'uploadFulfillment' ? 'api/v1/fc/products/bulk-upload/' : 'api/v1/orders-upload/'
        axios.post(url, formData, {headers: this.headers})
          .then((response) => {
            this.progress = 100
            clearInterval(this.myProgress)
            this.$vs.notify({
              color:'success',
              title:i18nData[this.$i18n.locale]['Success'],
              text: response.data.message,
              position: 'top-center'
            })
            this.closeInterval = setInterval(() => {
              this.closeShipmentsModal()
            }, 700)
            if (this.releaseVersion !== 'staging') {
              this.$mixpanel.track('UPLOAD_V2_LEGACY', {
                merchant_name: this.merchant.name,
                merchant_email: this.userMerchant.email,
                source: /(android)/i.test(navigator.userAgent)
              })
            }
          })
          .catch((error) => {
            this.progress = 100
            clearInterval(this.myProgress)
            common.notifyResponseError(this, error)
          })
      } else {
        this.$emit('upLoadAutoZoning', file)
        this.$emit('uploadShipmentsModal', false)
      }
    }
  },
  components: {
    ShipbluPrompt
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
</style>