<template>
  <div class="mt-5">
    <div class="mb-5 grid grid-cols-2 gap-6" v-if="showCards">
      <div class="card success px-6 py-4 flex items-center gap-5">
        <div class="icon flex items-center justify-center w-14 h-14 shrink-0">
          <p class="text-white flex items-center justify-center material-icons-outlined text-xl font-semibold">done</p>
        </div>
        <div class="w-full">
          <div class="flex justify-between items-center">
            <p class="leading-tight">
              <span class="text-primary font-medium text-4xl">{{ data.filter(element => element.customer.full_name && element.status === 200).length }}</span>
              <span class="text-darkgray text-lg">{{ ' /' + data.filter(element => element.customer.full_name).length }}</span>
            </p>
            <p class="text-lg" v-if="data.filter(element => element.customer.full_name && element.status === 200).length > 0">{{Math.round((data.filter(element => element.customer.full_name && element.status === 200).length/data.filter(element => element.customer.full_name).length)*100)}}%</p>
            <p class="text-lg" v-else>0%</p>
          </div>
          <p class="text-darkgray leading-tight font-medium text-xl">{{ $t('Successful orders') }}</p>
        </div>
      </div>
      <div class="card failed px-6 py-4 flex items-center gap-5">
        <div class="icon flex items-center justify-center w-14 h-14 shrink-0">
          <p class="text-white flex items-center justify-center material-icons-outlined text-xl font-semibold">close</p>
        </div>
        <div class="w-full">
          <div class="flex justify-between items-center">
            <p class="leading-tight">
              <span class="text-danger font-medium text-4xl">{{ data.filter(element => element.customer.full_name).length - data.filter(element => element.customer.full_name && element.status === 200).length }}</span>
              <span class="text-darkgray text-lg">{{ ' /' + data.filter(element => element.customer.full_name).length }}</span>
            </p>
            <p class="text-lg">{{Math.round(((data.filter(element => element.customer.full_name).length - data.filter(element => element.customer.full_name && element.status === 200).length)/data.filter(element => element.customer.full_name).length)*100)}}%</p>
          </div>
          <p class="text-darkgray leading-tight font-medium text-xl">{{ $t('Unsuccessful orders') }}</p>
        </div>
      </div>
    </div>
    <div class="table-scroll">
      <table class="w-full upload-table">
        <thead v-if="fillManualFlag">
          <th></th>
          <th>{{ $t('Full Name') }}</th>
          <th>{{ $t('Phone') }}</th>
          <th>{{ $t('Full Address') }}</th>
          <th>
            <div class="relative flex items-center justify-between gap-2 px-2 py-3" style="min-width: 457px;" :class="autoZoneFlag ? 'active-autoZone' : 'inactive-autoZone'">
              <div>{{ $t('Governorate') }}</div>
              <div>{{ $t('City') }}</div>
              <div class="flex items-center gap-2">
                <div>{{ $t('Zone') }}</div>
                <vs-switch name="auto zone" v-model="autoZoneFlag"/>
              </div>
              <vx-tooltip class="info-icon" position="top" color="dark" :text="`${$t('We will auto-select the Governorate, City & Zone values based on the “Address” field you fill')}`">
                <span class="material-icons-outlined text-sm">info</span>
              </vx-tooltip>
            </div>
          </th>
          <th>{{ $t('Order Type') }}</th>
          <th>{{ $t('Number of packages') }}</th>
          <th>{{ $t('COD') }}</th>
          <th class="relative">
            <div class="flex items-center justify-center gap-2 w-full">
              <p>{{ $t('Open package') }}</p>
              <vs-switch name="open package" v-model="openPackage"/>
            </div>
            <vx-tooltip class="info-icon" position="top" color="dark" :text="`${$t('Allow all packages to be opened')}`">
              <span class="material-icons-outlined text-sm m-1">info</span>
            </vx-tooltip>
          </th>
          <th class="relative">
            <div class="flex items-center justify-center gap-2 w-full">
              <p>{{ $t('Fragile') }}</p>
              <vs-switch name="fragile" v-model="fragile"/>
            </div>
            <vx-tooltip class="info-icon" position="top" color="dark" :text="`${$t('Mark all orders as Fragile')}`">
              <span class="material-icons-outlined text-sm m-1">info</span>
            </vx-tooltip>
          </th>
          <th v-for="(item, index) in extraCols" :key="index" class="relative">
            <p v-if="item.name !== 'Counter Drop Off'">{{ $t(item.name) }}</p>
            <div v-else>
              <vx-tooltip class="info-icon" position="top" color="dark" :text="`${$t('Choose this option if you will drop the package to the nearest ShipBlu branch')}`">
                <span class="material-icons-outlined text-sm m-1">info</span>
              </vx-tooltip>
              <div class="flex items-center justify-center gap-2 w-full">
                <p>{{ $t(item.name) }}</p>
                <vs-switch name="Counter Drop Off" v-model="counterDropOff"/>
              </div>
            </div>
          </th>
          <th>
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div class="dropdown-btn">
                <p class="mr-6">{{ $t('Optional columns') }}</p>
              </div>
              <vs-dropdown-menu class="optional-columns-dropdown">
                <div v-for="(item, index) in optionalCols" :key="index">
                  <vs-checkbox v-model="item.checked" :name="item.name">{{ $t(item.name) }}</vs-checkbox>
                </div>
              </vs-dropdown-menu>
            </vs-dropdown>
          </th>
        </thead>
        <draft-order-row :fillManualPressed="fillManualPressed" :data="data" :extraCols="extraCols" :fillManualFlag="fillManualFlag" :replacedFile="replacedFile" :userMerchant="userMerchant"
          :deletedFile="deletedFile" :sendOrdersFlag="sendOrdersFlag" :autoZoneFlag="autoZoneFlag" @data="data = $event" :merchant="merchant"
          :uploadFileFlag="uploadFileFlag" :originFile="originFile" :fileDetails="fileDetails" @fileDetails="fileDetails = $event" @replacedFile="replacedFile = $event" 
          :rows="rows" @deleteFile="deleteFile" @deletedFile="deletedFile = $event" @addExtraRows="addExtraRows" @allowSubmit="allowSubmit = $event" :AWBIds="AWBIds" :optionalCols="optionalCols" ></draft-order-row>
      </table>
    </div>
    <div v-if="fillManualFlag" class="relative flex items-center my-5">
      <div class="absolute add-rows">
        <span class="text-sm font-medium py-1 px-2 rounded tooltip bg-white text-primary">{{ $t('Add Extra Rows') }}</span>
      </div>
      <div class="p-1 flex self-center bg-primary rounded">
        <feather-icon @click="addExtraRows(20)" class="cursor-pointer" icon="PlusIcon" svgClasses="w-5 h-5 text-white" />
      </div>
    </div>
    <div v-if="fillManualFlag" class="submition py-6 px-16 text-right">
      <vs-button v-show="!showButtons()" class="font-medium text-lg" @click="calculateCODOrders()"
        :disabled="data.length === 0 || data.every(item => (item.status === 200 || (item.status === 0 && item.customer.full_name === '')))">
        {{ $t('Submit') }}
      </vs-button>
      <template v-if="showButtons()">
        <vs-button type="border" class="font-medium text-lg mr-5" @click="printAWB()">
          <feather-icon icon="PrinterIcon" class="mr-2" svgClasses="w-4 h-4" />{{ $t('Print AWB') }}
        </vs-button>
        <vs-button class="font-medium text-lg" @click="orderViewPage()">
          {{ $t('View Orders') }} 
        </vs-button>
      </template>
    </div>
    <!-- COD confirmation -->
    <shipblu-prompt
      class="no-COD text-center"
      @close="confirmCOD = false"
      @cancel="confirmCOD = false"
      :active.sync="confirmCOD"
      :title="''"
      :buttons-hidden="true">
      <img src="@/assets/images/pages/no-COD.svg" alt="">
      <p class="text-darkgray font-semibold text-lg">{{ $t('All added orders are with') }} <span class="text-red">0.00 EGP</span> {{ $t('COD') }}</p>
      <p class="font-medium text-xl text-dark">{{ $t('Are you sure you want to proceed?') }}</p>
      <div class="flex items-center justify-center gap-4 mt-6">
        <vs-button class="font-medium text-lg w-40" @click="confirmCOD = false" type="border" color="#9DADC2">{{ $t('Cancel') }}</vs-button>
        <vs-button class="font-medium text-lg w-40" @click="sendOrdersFlag = true, confirmCOD = false, showCards = true">{{ $t('Confirm') }}</vs-button>
      </div>
    </shipblu-prompt>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import DraftOrderRow from './DraftOrderRow.vue'
import ShipbluPrompt from '@/layouts/components/ShipBluPrompt.vue'
import axios from 'axios'
import i18nData from '../../../i18n/i18nData'

export default {
  props:[
    'uploadFileFlag', 'originFile', 'fillManualFlag', 'deletedFile', 'fileDetails', 'fillManualPressed', 'replacedFile', 
    'merchantInsurance', 'merchant', 'userMerchant'
  ],
  data () {
    return {
      data: [],
      AWBIds: [],
      optionalCols: [
        {
          name: 'Address Line 2',
          value: 'line_2',
          checked: false
        },
        {
          name: 'Email',
          value: 'email',
          checked: false
        },
        {
          name: 'Secondary Phone',
          value: 'secondary_phone',
          checked: false
        },
        {
          name: 'Service Level',
          value: 'service_level',
          checked: false
        },
        {
          name: 'Delivery Notes',
          value: 'order_notes',
          checked: false
        },
        {
          name: 'Merchant Reference Number',
          value: 'merchant_order_reference',
          checked: false
        },
        {
          name: 'Counter Drop Off',
          value: 'is_counter_dropoff',
          checked: false
        },
        {
          name: 'Products Declared Value',
          value: 'declared_value',
          checked: false
        },
        {
          name: 'Discount Code',
          value: 'discount_code',
          checked: false
        },
        {
          name: 'Merchant Location',
          value: 'pickup_point',
          checked: false
        }
      ],
      extraCols: [],
      autoZoneFlag: localStorage.getItem('autoZone') ? localStorage.getItem('autoZone') !== 'false' : true,
      openPackage: false,
      fragile: false,
      rows: 0,
      sendOrdersFlag: false,
      confirmCOD: false,
      showCards: false,
      counterDropOff: false,
      releaseVersion: process.env.VUE_APP_RELEASE_VERSION

    }
  },
  watch: {
    deletedFile (val) {
      this.$emit('deletedFile', val)
    },
    replacedFile (val) {
      this.$emit('replacedFile', val)
    },
    autoZoneFlag (val) {
      localStorage.setItem('autoZone', val)
      if (val === false) {
        if (this.releaseVersion !== 'staging') {
          this.$mixpanel.track('UPLOAD_V2_DISABLE_AUTOZONING', {
            merchant_name: this.merchant.name,
            merchant_email: this.userMerchant.email,
            source: /(android)/i.test(navigator.userAgent)
          })
        }
      }
    },
    fillManualPressed (val) {
      if (val) {
        this.rows = 40
        for (let index = 0; index < this.rows; index++) {
          this.data.push({
            pickup_point: '',
            errors: {},
            cities: [],
            zones: [],
            fileName: '',
            status: 0,
            customer: {
              full_name: '',
              phone: '',
              address: {
                line_1: '',
                zone: '',
                city: '',
                governorate: ''
              }
            },
            email: '',
            secondary_phone: '',
            type: '',
            number_of_packages: '',
            cash_amount: '',
            is_customer_allowed_to_open_packages: false,
            fragile: false,
            service_level: '',
            loadRow: false,
            order_notes: '',
            merchant_order_reference: '',
            is_counter_dropoff: false,
            line_2: '',
            declared_value: '',
            discount_code: ''
          })
        }
      }
    },
    openPackage (val) {
      if (val === true) {
        this.data.forEach(item => {
          if (item.status !== 200) {
            item.is_customer_allowed_to_open_packages = true
          }
        })
      } else {
        this.data.forEach(item => {
          if (item.status !== 200) {
            item.is_customer_allowed_to_open_packages = false
          }
        })
      }
    },
    fragile (val) {
      if (val === true) {
        this.data.forEach(item => {
          if (item.status !== 200) {
            item.fragile = true
          }
        })
      } else {
        this.data.forEach(item => {
          if (item.status !== 200) {
            item.fragile = false
          }
        })
      }
    },
    counterDropOff (val) {
      if (val === true) {
        this.data.forEach(item => {
          if (item.status !== 200) {
            item.is_counter_dropoff = true
          }
        })
      } else {
        this.data.forEach(item => {
          if (item.status !== 200) {
            item.is_counter_dropoff = false
          }
        })
      }
    },   
    'optionalCols': {
      handler (val) {
        if (val) {
          this.extraCols = val.filter(item => item.checked)
        }
      },
      deep: true
    },
    'merchantInsurance' () {
      if (this.merchantInsurance.insurance_package.id && this.merchantInsurance.insurance_package.id === 1) {
        this.optionalCols.splice(7, 1)
      }
    }
  },
  methods: {
    deleteFile () {
      this.$emit('deleteFile', this.originFile)
    },
    calculateCODOrders () {
      if (this.data.filter(element => element.cash_amount > 0).length === 0) {
        this.confirmCOD = true
        this.sendOrdersFlag = false
      } else {
        this.showCards = true
        this.sendOrdersFlag = true
      }
      setTimeout(() => {
        this.sendOrdersFlag = false
      }, 2000)
    },
    addExtraRows (numberOfRows) {
      for (let index = 0; index < numberOfRows; index++) {
        this.data.push({
          pickup_point: '',
          errors: {},
          cities: [],
          zones: [],
          fileName: '',
          status: 0,
          customer: {
            full_name: '',
            phone: '',
            address: {
              line_1: '',
              zone: '',
              city: '',
              governorate: ''
            }
          },
          email: '',
          secondary_phone: '',
          type: '',
          number_of_packages: '',
          cash_amount: '',
          is_customer_allowed_to_open_packages: false,
          fragile: false,
          service_level: '',
          loadRow: false,
          order_notes: '',
          merchant_order_reference: '',
          is_counter_dropoff: false,
          line_2: '',
          declared_value: '',
          discount_code: ''
        })
      }
    },
    printAWB () {
      axios({
        url: `${process.env.VUE_APP_API_URL}/api/v1/orders/shipping-label/?type=pdf&tracking_numbers=${this.AWBIds}&minimized=false`,
        method: 'GET',
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`
        }
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'ShipBlu-Shipping-Labels.pdf')
        document.body.appendChild(link)
        link.click()
      }).catch((error) => {
        if (error.response && error.response.status && error.response.status === 400) {
          this.$vs.notify({
            color:'danger',
            title: i18nData[this.$i18n.locale]['Failed'],
            text: i18nData[this.$i18n.locale]['Cannot generate a shipping label for unzoned orders.'],
            position: 'top-center'
          })
        }
        this.$vs.loading.close('#print-btn-loading > .con-vs-loading')
      })
    },
    orderViewPage () {
      this.$router.push({
        name: 'merchant-shipments'
      }).catch(() => {})
    },
    showButtons () {
      const validRows = this.data.filter(element => element.customer.full_name)
      return (this.data.filter(element => element.customer.full_name && element.status === 200).length === validRows.length) && (validRows.length !== null && validRows.length !== 0)
    }
  },
  components: {
    vSelect,
    DraftOrderRow,
    ShipbluPrompt
  }
}
</script>

<style lang="scss">
.card {
  .shrink-0 {
    flex-shrink: 0;
  }
  background: #FFFFFF;
  border-radius: 13px;
  &.success {
    color: #17814C;
    border: 1px solid #17814C;
    .icon {
      background: rgba(23, 129, 76, 0.15);
      border-radius: 50%;
      p {
        border-radius: 50%;
        width: 24px;
        height: 24px;
        border: 1px solid #17814C;
        background: #70BE97;
      }
    }
  }
  &.failed {
    color: #FE8383;
    border: 1px solid #FE8383;
    .icon {
      background: rgba(226, 127, 131, 0.26);
      border-radius: 50%;
      p {
        border-radius: 50%;
        width: 24px;
        height: 24px;
        border: 1px solid #D21C1C;
        background: #FE8383;
      }
    }
  }
}
.no-COD .vs-dialog > div:first-child {
  display: none;
}
.table-scroll {
  overflow: auto;
  padding: 0px 5px 0px 0px;
}
.upload-table {
  width: 100%;
  min-width: 1800px;
  text-align: center;
  border-collapse: separate;
  border-spacing: 0px 6px;
  position: relative;
  thead {
    background: #EDF4FB;
    z-index: 3;
    th {
      padding: 6px;
      text-align: center;
      font-weight: 500;
      font-size: 12px;
      color: #0A3266;
      &:first-child {
        border-top-left-radius: 6px !important;
        border-bottom-left-radius: 6px !important;
        position: -webkit-sticky;
        position: sticky;
        left: 0;
        z-index: 2;
        background: #EDF4FB;
        width: 32.16px;
      }
      @media (min-width: 1025px) {
        &:nth-of-type(2){
          position: -webkit-sticky;
          position: sticky;
          left: 32.16px;
          background: #EDF4FB;
          z-index: 2;
          min-width: 100px;
        }
        &:nth-of-type(3){
          position: -webkit-sticky;
          position: sticky;
          left: 139.16px;
          background: #EDF4FB;
          z-index: 2;
          min-width: 100px;
        }
        &:nth-of-type(4){
          position: -webkit-sticky;
          position: sticky;
          left: 246.16px;
          background: #EDF4FB;
          z-index: 2;
          min-width: 100px;
        }
      }
      &:last-child {
        border-top-right-radius: 6px !important;
        border-bottom-right-radius: 6px !important;
      }
    }
  }
  tbody {
    td {
      font-weight: 400;
      font-size: 12px;
      color: #0A3266;
      padding: 0px 3.5px;
      height: 35px;
      &:first-child {
        position: -webkit-sticky;
        position: sticky;
        left: 0;
        z-index: 2;
        width: 32.16px;
      }
      @media (min-width: 1025px) {
        &:nth-of-type(2){
          position: -webkit-sticky;
          position: sticky;
          left: 32.16px;
          background: #F8F8F8;
          z-index: 2;
        }
        &:nth-of-type(3){
          position: -webkit-sticky;
          position: sticky;
          left: 139.16px;
          background: #F8F8F8;
          z-index: 2;
        }
        &:nth-of-type(4){
          position: -webkit-sticky;
          position: sticky;
          left: 246.16px;
          background: #F8F8F8;
          z-index: 2;
        }
      }
      &:last-child {
        div {
          background: #EFEDED;
          border-radius: 6px;
          pointer-events: none;
        }
      }
    }
  }
  .vs-switch {
    transform: scale(0.75);
  }
  .dropdown-btn {
    &:before {
      content: "";
      position: absolute;
      top: calc(50% - 3.75px);
      right: 6px;
      border-left: 7.5px solid transparent;
      border-right: 7.5px solid transparent;
      border-top: 7.5px solid #1C5BFE;
      pointer-events: none;
    }
  }
  .v-select {
    &.address {
      width: 100%;
      min-width: 150px;
      .vs__selected {
        width: 90px;
        white-space: nowrap;
        overflow: hidden;
      }
    }
    background: #fff;
    border-radius: 6px;
    position: relative;
    &.order-type {
      .vs__selected {
        color: #0A3266;
        white-space: nowrap;
      }
      .vs__dropdown-toggle {
        border: none;
      }
    }
    .vs__selected {
      color: #1C5BFE;
      padding: 0px;
    }
    .vs__actions {
      padding: 4px 2px 0px 3px;
      .vs__clear {
        margin-right: 0px;
      }
    }
    .vs__dropdown-toggle {
      border: 1px solid #C9C9C9;
      border-radius: 6px;
      height: 100%;
      min-width: 100px;
      .vs__selected-options {
        input.vs__search {
          position: absolute;
          inset: 0;
          z-index: -1;
        }
      }
    }
    .feather-chevron-down, .feather-x {
      fill: #E5E5E5;
      stroke: #E5E5E5;
    }
    ul {
      border: none;
      z-index: 2;
      margin-top: 0px;
      overflow-x: hidden;
      width: fit-content;
      li {
        padding: 3px 6px;
      }
    }
  }
  .input-number {
    position: relative;
    &:before {
      content: "";
      position: absolute;
      bottom: 6px;
      right: 6px;
      border-left: 7.5px solid transparent;
      border-right: 7.5px solid transparent;
      border-top: 7.5px solid #E5E5E5;
      pointer-events: none;
    }
    &:after {
      content: "";
      position: absolute;
      top: 6px;
      right: 6px;
      border-left: 7.5px solid transparent;
      border-right: 7.5px solid transparent;
      border-bottom: 7.5px solid #E5E5E5;
      pointer-events: none;
    }
    input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none !important;
      height: 35px;
      width: 15px;
      cursor: pointer;
    }
  }
  .info-icon {
    position: absolute;
    top: 0px;
    right: 1px;
    color: #6C84A3;
    cursor: pointer;
  }
  .active-autoZone {
    background: #D4E0FF;
    border: 1px solid #1C5BFE;
    border-radius: 6px;
    font-style: normal;
    font-weight: 600;
    color: #1C5BFE;
  }
  .inactive-autoZone {
    background: #FFFFFF;
    border: 1px solid #C7C7C7;
    border-radius: 6px;
    font-weight: 600;
    color: #A8A8A8;
  }
  .checkbox-input {
    background: #fff;
    border-radius: 6px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  input {
    background: #FFFFFF;
    border-radius: 6px;
    border: none;
    padding: 6px;
    font-weight: 400;
    font-size: 12px;
    color: #0A3266;
    width: 100%;
    min-width: 100px;
  }
  input[type='checkbox'] {
    min-width: unset !important;
  }
  .invalid-input {
    background: rgba(255, 196, 196, 0.28);
    border: 1px solid #FE8383;
  }
}
.submition {
  position: sticky;
  bottom: 0;
  background: #FFFFFF;
  z-index: 999;
  box-shadow: 0px -3px 4px rgba(0, 0, 0, 0.11);
  border-radius: 10px 10px 0px 0px;
}
.optional-columns-dropdown {
  ul {
    padding: 4px 8px !important;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    & > div {
      white-space: nowrap;
      background: #F9F9F9;
      border-radius: 4px;
      margin: 4px 0px;
      padding: 6px 5px;
      font-size: 12px;
      font-weight: 500;
      line-height: 13px;
      color: #0A3266;
    }
  }
}
.add-rows {
  left: 37px;
  .tooltip {
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: -11px;
      margin-top: -7px;
      border-width: 7px;
      border-style: solid;
      border-color: transparent transparent transparent #1C5BFE;
    }
  }
}
</style>